html {
    --reflinks-transition-duration: 0.3s;
    --reflinks-transition-x: 100px;
}

@keyframes reflinks-transition-left-to-right-out {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(calc(var(--reflinks-transition-x) * -1));
        opacity: 0;
    }
}

.reflinks-transition-left-to-right-out {
    animation: reflinks-transition-left-to-right-out var(--reflinks-transition-duration);
}

@keyframes reflinks-transition-left-to-right-in {
    0% {
        transform: translateX(var(--reflinks-transition-x));
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.reflinks-transition-left-to-right-in {
    animation: reflinks-transition-left-to-right-in var(--reflinks-transition-duration);
}

@keyframes reflinks-transition-right-to-left-out {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }

    100% {
        transform: translateX(var(--reflinks-transition-x));
        opacity: 0;
    }
}

.reflinks-transition-right-to-left-out {
    animation: reflinks-transition-right-to-left-out var(--reflinks-transition-duration);
}

@keyframes reflinks-transition-right-to-left-in {
    0% {
        transform: translateX(calc(var(--reflinks-transition-x) * -1));
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.reflinks-transition-right-to-left-in {
    animation: reflinks-transition-right-to-left-in var(--reflinks-transition-duration);
}
