:root {
    --modal-page-left: 341px;
    --modal-fullscreen-left: 60px;
}

.modal-central-open {
    animation: modal-central-open-keyframes 0.1s forwards;
}

.modal-central-close {
    animation: modal-central-close-keyframes 0.1s forwards;
}

.modal-lateral-open {
    animation: modal-lateral-open-keyframes 0.35s forwards;
}

.modal-lateral-close {
    animation: modal-lateral-close-keyframes 0.2s forwards;
}

@keyframes modal-central-open-keyframes {
    0% {
        transform: scale(80%);
        opacity: 0.5;
    }
    100% {
        transform: scale(100%);
        opacity: 1;
    }
}

@keyframes modal-central-close-keyframes {
    0% {
        transform: scale(100%);
        opacity: 1;
    }
    100% {
        transform: scale(80%);
        opacity: 0.5;
    }
}

@keyframes modal-lateral-open-keyframes {
    0% {
        transform: translateX(calc(var(--modal-lateral-largura) / 2));
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes modal-lateral-close-keyframes {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }
    100% {
        transform: translateX(calc(var(--modal-lateral-largura) / 2));
        opacity: 0;
    }
}
