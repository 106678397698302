/* devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(/fonts/Poppins/Poppins-Bold.ttf);
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(/fonts/Poppins/Poppins-Bold.ttf);
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(/fonts/Poppins/Poppins-Bold.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*
Font families defined by this CSS:

- "Inter"                  static "traditional" fonts for older web browsers
- "Inter var"              single-axis variable fonts for all modern browsers
- "Inter var experimental" multi-axis variable fonts for modern web browsers

Use like this in your CSS:

  :root { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    :root { font-family: 'Inter var', sans-serif; }
  }

------------------------- static ------------------------- */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(/fonts/Inter/Inter-Thin.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-Thin.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(/fonts/Inter/Inter-ThinItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-ThinItalic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(/fonts/Inter/Inter-ExtraLight.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-ExtraLight.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(/fonts/Inter/Inter-ExtraLightItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-ExtraLightItalic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Inter/Inter-Light.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-Light.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/Inter/Inter-LightItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-LightItalic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Inter/Inter-Regular.woff2?v=3.18) format('woff2'),
        url(/fonts/Intern/Inter-Regular.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Inter/Inter-Italic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-Italic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/Inter/Inter-Medium.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-Medium.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/Inter/Inter-MediumItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-MediumItalic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/Inter/Inter-SemiBold.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-SemiBold.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/Inter/Inter-SemiBoldItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-SemiBoldItalic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Inter/Inter-Bold.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-Bold.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Inter/Inter-BoldItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-BoldItalic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(/fonts/Inter/Inter-ExtraBold.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-ExtraBold.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(/fonts/Inter/Inter-ExtraBoldItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-ExtraBoldItalic.woff?v=3.18) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(/fonts/Inter/Inter-Black.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-Black.woff?v=3.18) format('woff');
}
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(/fonts/Inter/Inter-BlackItalic.woff2?v=3.18) format('woff2'),
        url(/fonts/Inter/Inter-BlackItalic.woff?v=3.18) format('woff');
}

/* ----------------------- variable ----------------------- */

@font-face {
    font-family: 'Inter var';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(/fonts/Inter/Inter-roman.var.woff2?v=3.18) format('woff2');
    font-named-instance: 'Regular';
}

@font-face {
    font-family: 'Inter var';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url(/fonts/Inter/Inter-italic.var.woff2?v=3.18) format('woff2');
    font-named-instance: 'Italic';
}
