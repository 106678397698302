input[type='checkbox'] {
    appearance: none;
    padding: 0;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    user-select: none;
    flex-shrink: 0;
    height: 15px;
    width: 15px;
    color: #4f46e5;
    background: white;
    border-color: #a1a1aa;
    border-width: 1.5px;
    border-radius: 2px;
    cursor: pointer;
}

input[type='checkbox']:hover {
    border-color: #71717a;
}

.dark input[type='checkbox'] {
    background: #1e2227;
    color: #4f46e5;
}

input[type='checkbox']:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
}

/* input[type='checkbox']:before {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    border: 1px solid #808080;
    content: '';
    background: #fff;
}

input[type='checkbox']:after {
    position: relative;
    display: block;
    left: 2px;
    top: -11px;
    width: 7px;
    height: 7px;
    border-width: 1px;
    border-style: solid;
    border-color: #b3b3b3 #dcddde #dcddde #b3b3b3;
    content: '';
    background-image: linear-gradient(135deg, #b1b6be 0%, #fff 100%);
    background-repeat: no-repeat;
    background-position: center;
}

input[type='checkbox']:checked:after {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'),
        linear-gradient(135deg, #b1b6be 0%, #fff 100%);
}

input[type='checkbox']:disabled:after {
    -webkit-filter: opacity(0.4);
}

input[type='checkbox']:not(:disabled):checked:hover:after {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'),
        linear-gradient(135deg, #8bb0c2 0%, #fff 100%);
}

input[type='checkbox']:not(:disabled):hover:after {
    background-image: linear-gradient(135deg, #8bb0c2 0%, #fff 100%);
    border-color: #85a9bb #92c2da #92c2da #85a9bb;
}

input[type='checkbox']:not(:disabled):hover:before {
    border-color: #3d7591;
} */
