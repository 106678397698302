.alo-treeselect .vue-treeselect__control {
    @apply rounded-sm border-gray-300 dark:border-gray-800;
}

.dark .alo-treeselect .vue-treeselect__control {
    @apply rounded-sm border-dark-900;
    @apply bg-dark-700 text-gray-200;
}

.alo-treeselect {
    @apply dark:bg-dark-800 dark:text-gray-200;
}

.vue-treeselect__multi-value-label {
    @apply dark:bg-dark-800;
}

.vue-treeselect__value-remove {
    @apply dark:border-dark-900;
}

.alo-treeselect .vue-treeselect__value-container {
    @apply pb-2;
}

.alo-treeselect .vue-treeselect__multi-value {
    @apply pt-1;
}

.alo-treeselect .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 0;
}

.alo-treeselect .vue-treeselect__placeholder {
    top: 3px !important;
}

.alo-treeselect .vue-treeselect__menu {
    @apply dark:bg-dark-700 dark:border-none;
}

.alo-treeselect .vue-treeselect__option--highlight {
    @apply dark:bg-primary-600 dark:text-gray-200;
}

.alo-treeselect .vue-treeselect__multi-value-item {
    @apply bg-white text-black text-sm;
    @apply dark:bg-dark-800 dark:text-gray-200 dark:border-dark-800;
}

.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) .vue-treeselect__control:hover {
    @apply dark:border-dark-900;
}
