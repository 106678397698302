.alo-select {
    @apply block border rounded-sm w-full border-gray-300 bg-white focus:border-primary-400 focus:ring-2 focus:ring-primary-200 text-gray-600;
    @apply dark:border-dark-900 dark:bg-dark-700 dark:focus:border-dark-600  dark:text-gray-200;
}

.vs__dropdown-menu {
    max-height: 200px !important;
    overflow: auto;
    @apply dark:bg-dark-700;
}

.vs__dropdown-option {
    @apply dark:text-gray-400;
}

.vs__dropdown-option--highlight {
    @apply dark:bg-primary-600 dark:text-white;
}

.alo-select .vs__search {
    @apply absolute;
}

.alo-select .vs__open-indicator {
    fill: currentColor;
    @apply dark:text-dark-100;
}

.alo-select.vs--open .vs__search {
    @apply relative;
}

.alo-select .vs__selected-options {
    @apply truncate;
    min-height: 1.75rem;
}

.alo-select .vs__selected {
    @apply truncate dark:text-gray-200;
}

.alo-select .vs__search::placeholder,
.alo-select .vs__dropdown-toggle,
.alo-select .vs__dropdown-menu {
    @apply border-none;
}

.alo-select .vs__search::placeholder {
    @apply text-gray-400 dark:text-gray-200;
}

.alo-select .vs__actions {
    @apply flex p-0 items-center mt-2 mr-3;
}

.style-select .vs__clear,
.style-select .vs__open-indicator {
    fill: #394066;
}
