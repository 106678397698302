teclia-rich-text {
    @apply block;
}

.rich-text-container {
    @apply h-full;
}

.rich-text-editor {
    @apply bg-white border border-gray-300 rounded-sm focus-within:border-primary-400 focus-within:ring-2 focus-within:ring-primary-200;
    @apply dark:bg-dark-700 dark:border-dark-900 rounded-sm  dark:text-gray-200;
}

.rich-text-container figure {
    @apply flex flex-col items-center;
}

.rich-text-container trix-toolbar {
    @apply border-b;
}

.rich-text-container trix-editor {
    border: none !important;
    @apply p-2;
}

.rich-text-container trix-toolbar .trix-button {
    width: 35px;
    height: 30px;
}

.rich-text-container .trix-button--icon-decrease-nesting-level {
    @apply hidden;
}

.rich-text-container .trix-button--icon-increase-nesting-level {
    @apply hidden;
}

.rich-text-container .trix-button--icon-quote {
    @apply hidden;
}

.rich-text-container .trix-button-group:not(:first-child) {
    @apply ml-0;
}

.rich-text-container trix-toolbar .trix-button::before {
    background-size: auto !important;
}

.rich-text-container trix-toolbar .trix-button-row {
    overflow: visible;
}

.rich-text-container trix-toolbar .trix-button-group--history-tools {
    @apply hidden;
}

.rich-text-container .trix-button-group {
    border: none !important;
    margin-bottom: 0;
}

.rich-text-container .trix-button {
    border: none !important;
}

.rich-text h1 {
    @apply text-3xl mt-2 mb-2;
}

.rich-text h4 {
    @apply font-bold text-xl mt-4 mb-2;
}

.rich-text p {
    @apply my-4 leading-relaxed;
}

.rich-text h1:not(:first-child) {
    @apply mt-6;
}

.rich-text ul {
    @apply list-disc pl-4 ml-2;
}

.rich-text ol {
    @apply list-decimal pl-4 ml-2;
}

.rich-text a {
    @apply text-primary-500 underline cursor-pointer;
}

.rich-text blockquote {
    @apply block border-l-2 py-0.5 border-gray-400 px-2 bg-black bg-opacity-5 opacity-90 mb-2;
}

.rich-text blockquote p {
    @apply block my-1;
}

.rich-text img {
    @apply inline-block mx-auto;
}

.rich-text figcaption {
    display: none;
}
