.table-helpdesk {
    @apply block border-separate w-full;
    border-spacing: 0;
}

.table-helpdesk thead,
.table-helpdesk tbody {
    @apply block w-full;
}

.table-helpdesk article {
    @apply relative block w-full py-1;
    z-index: 0;
}

.table-helpdesk article:hover {
    @apply z-10 shadow-md ring-1 ring-black ring-opacity-30;
}

.table-helpdesk .alerts {
    @apply ml-[60px];
}

.table-helpdesk .alerts > * {
    @apply mb-2;
}

.table-helpdesk tr {
    @apply flex w-full relative;
}

.table-helpdesk td {
    @apply inline-block cursor-pointer;
}

.table-helpdesk td.actions {
    @apply w-[60px] flex-shrink-0 flex items-center justify-center space-x-2;
}

.table-helpdesk td.contact {
    @apply w-[150px] max-w-[150px] flex-shrink-0 text-sm flex items-center truncate;
}

.table-helpdesk td.perfil {
    @apply w-[100px] max-w-[100px] flex-shrink-0 text-sm flex items-center truncate;
}

.table-helpdesk td.subject {
    @apply flex-grow text-sm truncate;
}

.table-helpdesk td.date {
    @apply w-[80px] flex-shrink-0 text-sm flex items-center relative;
}

.dark .table-helpdesk td {
    @apply cursor-pointer;
    border: solid 1px transparent;
    border-top: 1px solid #3a3f48;
    border-style: solid none;
}
